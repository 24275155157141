<template>
  <div class="layout-footer">
    <!-- <div class="tokuteisho" v-show="path !== `/tokuteisho/`">
      <router-link to="/tokuteisho/">特定商取引法に基づく表示</router-link>
    </div> -->
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
 
        <div class="copyright">
          <p>Copyright©<br class="sp-only">yuntaku All Rights Reserved.</p>
        </div>
      </div>
      <div class="sp-contact-overlay">
        <div class="mail-contact" v-scroll-to="'#contact'">
          <span>ご予約・お問い合わせ</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Noto+Serif+JP:wght@400;700&display=swap");
.layout-footer{
  font-family: var(--fontfamilyMain);
}
.footer {
  .fotter-inner {
    background-color: var(--footer);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px 40px 10px;
    @media screen and (max-width: 767px) {
      align-items: center;
      flex-direction: column;
    }
  }
  .copyright {
    font-size: 13px;
    color: white;
    // margin-right: 200px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      line-height: 1.3em;
    }
  }
  .sp-contact-overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 50px;
      // background-color: rgba(var(--rgb-accent),0.8);
      background-color: rgba(white,0.7);
      align-items: center;
      justify-content: center;
      z-index: 999;
      .mail-contact {
        width: 90%;
        // border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--accent);
        span {
          text-decoration: none;
          color: white;
          // font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}
</style>
