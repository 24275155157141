<template>
  <div class="sample-flow wrap space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

         <div class="cover">
      <SampleFlowFlowBox
        v-for="flowBoxListData in flowBoxList"
        :key="flowBoxListData.id"
        :no="flowBoxListData.no"
        :title="flowBoxListData.title"
        :text="flowBoxListData.text"
        :yazirushi="flowBoxListData.yazirushi"
        class="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleFlowFlowBox from "@/components/Sample/Flow/FlowBox.vue";

export default {
  name: "SampleQa",
  components: {
    UiHeading,
    SampleFlowFlowBox,
  },
  props: {
    flowBoxList: Array,
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>