<template>
  <div class="sample">
    <div class="top">
      <img
        alt="yuntakuPCトップ"
        :src="require(`@/assets/${headerImgPathPc}`)"
        class="pc-only"
      />
      <img
        alt="yuntakuspトップ"
        :src="require(`@/assets/${headerImgPathSp}`)"
        class="sp-only"
      />
    </div>

    <div class="space-top-70">
      <SampleAboutUs
        class="space-top-50"
        id="about"
        :headingText="`サロンyuntaku<br class=sp-only>について`"
        :description="`&quot;からだ&quot; と &quot;こころ&quot; のつながりは、自律神経やホルモン、免疫系と深く関わりとても重要です。天然アロマオイルを使い、頭部・首・肩・デコルテや背骨・フットマッサージをすることで脳疲労による肌老化の改善にもつながります。 &lt;br&gt;&lt;br&gt;サロンyunyakuでは、 &lt;br&gt;&quot;からだ&quot;が示すメッセージを解読し&quot;本来のあなた&quot;へ向かう癒しの旅をサポートします。 &lt;br&gt;&lt;br&gt;偏った&quot;からだ（器）&quot;を外側から整いへとおつなぎすることで、見た目の変化とともに内側への〝気づき〟を加速させていきます。&lt;br&gt;&lt;br&gt;本来の自分への還りを始めるために、幸せへの第一歩は &quot;今ここから&quot;`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back01)">
      <SampleFeature
        class="space-top-80"
        :headingText="'サロンyuntakuの特徴'"
        :featureBoxList="featureBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleUsers
        class="space-top-80"
        id="user"
        :headingText="`こんな人が利用しています`"
        :usersList="usersList"
      />
    </div>

    <div
      class="space-top-70 introduction-back"
      :class="{
        back01: path === '/sample01',
        back02: path === '/sample02',
        back03: path === '/sample03',
        back04: path === '/sample04',
        back05: path === '/sample05',
        back06: path === '/sample06',
        back07: path === '/sample07',
        back08: path === '/sample08',
        back09: path === '/sample09',
        back10: path === '/sample10',
        back11: path === '/sample11',
        back12: path === '/sample12',
        back13: path === '/',
      }"
    >
      <SampleTherapistTypeB
        class="space-top-80"
        id="introduction"
        :headingText="'セラピストの紹介'"
        :imgPath="'therapy-top-hito_fix.jpg'"
        :name="'やおいた さとみ'"
        :roman="'Satomi Yaoita'"
        :description="'代替医療があたりまえの環境で育った沖縄出身の やおいたさとみです。私は、様々な分岐点を経験する中で、足裏が全身の反射区であることを知って感動したのをキッカケに、アロマ、漢方、レイキ、ヒプノセラピー(催眠療法)、ラックファクター(運氣の調整)、気功、ヨーガなど、さまざまな視点から心と体の関係について学んできました。これらを組み合わせながら、癒しをメインに&quot;気持ちが楽～になり、心がゆる～くなる&quot;セラピーをおこなっています。見えにくい本来の感覚から引き起こされた&quot;ねじれ&quot;や&quot;淀み&quot;がフラットに削ぎ落されていき、生きやすさにもつながり、本来の自分を見つけやすくなります。ぜひ、ご体験ください。'"
        :therapistTrouble="therapistTrouble"
                :therapistSkill="therapistSkill"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleMessage
        class="space-top-80"
        :headingText="`セラピストの強みとセラピーにかける想い`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleMenu
        class="space-top-80"
        id="menu"
        :headingText="'セラピーメニュー'"
        :menuBoxList="menuBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleAccess
        class="space-top-80"
        id="access"
        :headingText="`ご利用方法・アクセス`"
        :howToOnline="`サロンyuntakuでは、ご自宅やお好きな場所からパソコンやスマートフォンなどを利用したオンラインセラピーを行っております。オンラインセラピーは、Zoom(ズーム)というオンラインビデオ通話サービスを利用し、ご利用料金は無料です。<br><br>オンラインセラピーの際、あらかじめZoomアプリのインストールをお願いいたします。
ご予約時に事前にお送りするメールに記載されたURLをクリックすると、自動的にZoomがインストールされますので、あらかじめインストールしておく必要はありません。<br><br>恐れ入りますが、Zoomの設定・接続に関して、当サロンではサポートいたしかねます。
オンラインセラピーのご利用について不明な点がございましたら、お問い合わせよりお気軽にご質問ください。`"
        :howToMeet="`サロンyuntakuは、藤沢市内商業施設にほど近い住宅街の中にございます。ご予約が確定しましたら、詳細な場所をお伝えさせていただきます。<br>自宅サロンではありますが、セラピールームは、プライベートな空間になっています。<br>その日の気分で選んでいただくアロマの香りと心地よい音楽で、ゆったりとリラックスしていただきながら、セラピーに臨んでいただけます。<br>心の変化と同様に、体調も日々変化していますので、カウンセリングは心と体に寄り添った形で、時間をかけて丁寧におこなわせていただきます。<br>遠方からお越しの際には、最寄り駅までお迎えにまいりますので、ご予約時にお申し付けください。`"
        :addressTableList="addressTableList"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back01)">
      <SampleVoice
        class="space-top-80"
        id="voice"
        :headingText="'お客様の声'"
        :voiceBoxList="voiceBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleQa
        class="space-top-80"
        id="question"
        :qaBoxList="qaBoxList"
        :headingText="`よくある質問`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleFlow
        class="space-top-80"
        id="flow"
        :headingText="'ご予約から<br class=sp-only>セラピーまでの流れ'"
        :flowBoxList="flowBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleReserve
        class="space-top-80"
        id="contact"
        :headingText="`セラピーの<br class=sp-only>ご予約・お問い合わせ`"
      />
    </div>

    <!-- <div class="space-top-70" style="background-color: var(--back02)">
      <SampleInfo
        class="space-top-80"
        :headingText="'お知らせ'"
        :infoBoxList="infoBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleBlog
        class="space-top-80"
        :headingText="'ブログ'"
        :blogBoxList="blogBoxList"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SampleAboutUs from "@/components/Sample/AboutUs.vue";
import SampleFeature from "@/components/Sample/Feature.vue";
import SampleUsers from "@/components/Sample/Users.vue";
import SampleTherapistTypeB from "@/components/Sample/TherapistTypeB.vue";
import SampleMessage from "@/components/Sample/Message.vue";
import SampleMenu from "@/components/Sample/Menu.vue";
import SampleAccess from "@/components/Sample/Access.vue";
import SampleVoice from "@/components/Sample/Voice.vue";
import SampleQa from "@/components/Sample/Qa.vue";
import SampleFlow from "@/components/Sample/Flow.vue";
import SampleReserve from "@/components/Sample/Reserve.vue";
// import SampleInfo from "@/components/Sample/Info.vue";
// import SampleBlog from "@/components/Sample/Blog.vue";
export default {
  name: "sample",
  components: {
    SampleAboutUs,
    SampleFeature,
    SampleUsers,
    SampleTherapistTypeB,
    SampleMessage,
    SampleMenu,
    SampleAccess,
    SampleVoice,
    SampleQa,
    SampleFlow,
    SampleReserve,
    // SampleInfo,
    // SampleBlog,
  },
  props: {
    headerImgPathPc: String,
    headerImgPathSp: String,
    path: String,
  },
  data() {
    return {
      featureBoxList: [
        {
          id: 1,
          imgPath: "026.webp",
          title: "脳内の幸せホルモンを増やす",
          text: "ほんのひとときでも日常から離れて自分自身と向き合い、こころとからだの調和のために疲れを癒し、内面の声に耳を傾ける時間をつくってあげてください。マッサージなど、タッチセラピーで心地よい時間を過ごしながら内なる平穏を取り戻すことができます。幸せを感じやすくする脳内物質ホルモンを分泌して、ポジティブな気持ちが沸き上がって活動的になるうえに、アンチエイジングや直感力を高める効果も！",
        },
        {
          id: 2,
          imgPath: "005.webp",
          title: "完全オーダーメイドな<br />「セッションスタイル」",
          text: "自分次第で変化できる可能性があります‼<br /><br />自分の思考・感情・執着で日々形づくられていき常に変化しているのが、\"からだ\" です。<br /><br />外から無理やりコントロールするのではなく、\"からだ\"の場所から出ているサインと対話しながら、癒しで気づきの循環の風通しを良くすることを目的としたカスタマイズな施術にこだわっています。",
        }
      ],
      usersList: [
        {
          id: 1,
          users: "頭皮が硬い",
        },
        {
          id: 2,
          users:
            "首がストレート気味",
        },
        {
          id: 3,
          users:
            "寝つきが悪い",
        },
        {
          id: 4,
          users:
            "足の浮腫み＆肩こり",
        },
        {
          id: 5,
          users:
            "疲れがとれない",
        },
        {
          id: 6,
          users: "リラックスの仕方<br class=pc-sp-only-users>がわからない",
        },
        {
          id: 7,
          users:
            "自信を持ちたい・<br class=pc-sp-only-users>ポジティブ思考になりたい",
        },
        // {
        //   id: 8,
        //   users: "視力の衰えを感じ、<br class=pc-sp-only-users>目がかすむ",
        // },
        // {
        //   id: 9,
        //   users:
        //     "嫌な思い出が消えず<br class=pc-sp-only-users>相談できる人がいない",
        // },
      ],
      therapistTrouble: [
        {
          id: 1,
          case: "癒し",
        },
        {
          id: 2,
          case: "不眠",
        },
        {
          id: 3,
          case: "自信",
        },
        {
          id: 4,
          case: "体調回復",
        },
        {
          id: 5,
          case: "浮腫み",
        },
        {
          id: 6,
          case: "顔の老化",
        },
      ],
            therapistSkill:[
      {
          id: 1,
          case: "米国催眠士協会（NGH）認定セラピスト",
        },
        {
          id: 2,
          case: "ABH認定ヒプノティスト",
        },
        {
          id: 3,
          case: "JAA日本アロマ協会認定セラピスト",
        },
        {
          id: 4,
          case: "レイキ",
        },
        {
          id: 5,
          case: "リフレクソロジスト",
        },
      ],
      menuBoxList: [
        {
          id: 1,
          title: "脳（こころ）セラピー〖頭・肩・首に特化したセッション〗",
          meeting: true,
          online: false,
          price: 7000,
          separatePrice: false,
          time: "1時間",
          text: "脳疲労は、体にも悪影響を与えます‼ 肥満や高血圧、糖尿病などのいわゆる心身症（ストレス病）を発症し、睡眠の質や朝の目覚めを阻害する要因にもなります。治癒力を高めるためにも脳～首、肩周りをマッサージして心身ともにゆるめて、脳内秘薬（ハッピーホルモン）を分泌＆幸福感を感じてみるのはいかがですか？",
        },
        {
          id: 2,
          title: "&quot;からだ&quot;セラピー〖フット✖背骨✖ヘッドマッサージ〗",
          meeting: true,
          online: false,
          price: 10000,
          separatePrice: false,
          time: "1時間30分",
          text: "寝ても疲れがとれない！頭痛や、目の疲れ、めまい、肩こり、猫背、背中の凝りなどには、足つぼや、肩首回り、背骨のゆるむオールハンドマッサージをすることで血流が良くなり、代謝もアップし老廃物を出すサポートです。さらに、自律神経にも働きかけるため疲れきってしまった&quot;からだ&quot;を癒し、「私の心地いい」感覚を思いだすことができます。",
        },
        {
          id: 3,
          title: "Yuju(つなぐ)：〖独自のトータル・ヒーリングセッション〗",
          meeting: true,
          online: true,
          price: 20000,
          separatePrice: false,
          time: "2時間30分",
          text: "疲れ切った脳・顔・体が蘇る‼<br>からだ と こころ から「脳内を究極に癒す」ことを追求したサロンです。 顔の浮腫みもとれ、脳内の癒しにつながるヨーガ療法やヒプノ・アロマ・タッチセラピー、ヒーリング、エネルギー療法などを盛りこみ、お時間、お値段、結果ともに効率と質の高い内容を提供しています。全身の癒しとデトックス、そして潜在意識へのアクセスすることで&quot;本来の自分&quot;を取り戻す時間をぜひご実感ください。",
        },
        // {
        //   id: 4,
        //   title: "ハートフル瞑想＆リラクゼーション<br class=sp-only>〖３回コース〗",
        //   meeting: true,
        //   online: true,
        //   price: "24,000円〖 3回 〗",
        //   separatePrice: true,
        //   time: "各２時間",
        //   text: "過去のトラウマや不安に囚われ、日々「生きずらさ」を感じている方へ。過去の習慣やトラウマから解放され、より充実した人生を歩むために数回のセッションをしていただくことをお勧めしています。潜在意識の心奥深くに眠る問題やブロックを、自身で気づきを得て解放する作業を持続しけることが習慣となり、自分自身で癒せることができるようになります。アロママッサージなど、体からもアプローチし感情を癒していきます。",
        // },
        // {
        //   id: 5,
        //   title: "足つぼ＆リフレクソロジー講座〖3回コース〗",
        //   meeting: true,
        //   online: false,
        //   price: "30,000円〖 3回 〗",
        //   separatePrice: true,
        //   time: "２時間",
        //   text: "この講座では、プロのマッサージテクニックや手技をマンツーマンで学びながら自分自身や大切な人に心地よいフットマッサージを施す方法を身につけます。初心者でも安心して参加できるよう足裏のツボや筋肉の特徴を学びながら、実践的なワークショップ形式でおこないます。足の疲れやむくみを解消し、全身のバランスを整える技術を身につけられます。仕事として技術を学びたい方も、別途ご相談にのらせていただきます。",
        // },
        // {
        //   id: 1,
        //   title: "【初回限定】<br>カウンセリング×ヒプノ×ヨーガorフットマッサージ",
        //   meeting: true,
        //   online: true,
        //   price: 5000,
        //   separatePrice: false,
        //   time: "2時間",
        //   text: "初めてyuntakuでご予約をされる方限定の、初回お試しセラピーです。催眠療法を体験してみたい方や、イメージ療法で心地良さを感じてみたい方におすすめです。心身をゆるませながら自分を癒すことができます。こちらのメニューは、複数のセラピーメニューが一度に体験できる、とてもお得なコースとなっています。ぜひ、お気軽にお試しください。",
        // },
        // {
        //   id: 2,
        //   title: "【願望達成セラピー】<br>暗示療法×ヨーガorフットマッサージ",
        //   meeting: true,
        //   online: true,
        //   price: 7000,
        //   separatePrice: false,
        //   time: "1時間30分",
        //   text: "「痩せたーい！のに・・・」と、ダイエットがなかなか成功しない方には、暗示療法が有効です。ヨーガで心身をつなぎ、繰り返し暗示を与えることで、潜在意識に成功をインストールさせていきます。また、ダイエット以外にも、禁煙、不眠症の改善、自信をもって話す、手術の前に心を落ち着ける、パニック障害の改善、記憶力を高めるなど、あらゆる願望・ゴールの達成をサポートします。ストレスマネジメントにも効果的です。",
        // },
        // {
        //   id: 3,
        //   title: "【リラクゼーションセラピー】ヨーガ瞑想×ヒプノセラピー",
        //   meeting: false,
        //   online: true,
        //   price: 18000,
        //   separatePrice: false,
        //   time: "2時間",
        //   text: "過去のトラウマや不安に囚われ、日々「生きずらさ」を感じている方へ。丁寧なカウンセリングと自分の体に意識を向けるヨーガ瞑想を行った後、退行型インナーチャイルドや前世療法などのヒプノセラピー(催眠療法)を行います。そして「記憶の宝庫」とも呼ばれる場所へアクセスして必要な情報を引き出し、智慧や気づきを得て解放と浄化を促します。深いリラクゼーションから時空を超えた小旅行へ行くような感覚を体験してください。",
        // },
        // {
        //   id: 4,
        //   title: "ヒプノセラピー〖退行型インナーチャイルド〗",
        //   meeting: false,
        //   online: true,
        //   price: 10000,
        //   separatePrice: false,
        //   time: "1時間30分",
        //   text: "常に「自分が悪い」と感じて謝罪したり、責任を取ろうとしたり、「自分は嫌われている」と感じて人との関わりをためらってしまう方へ。これらの反応は、子供時代に形成された思考パターンや習慣が影響していると考えられます。他人と対等な関係を築くことが苦手で「生きずらさ」を感じている方は、インナーチャイルドと向き合い、深層心理を客観視することで、心をケアしながら、トラウマと現在の自分を切り離すことができます。",
        // },
        // {
        //   id: 5,
        //   title: "ヒプノセラピー〖前世療法〗",
        //   meeting: false,
        //   online: true,
        //   price: 10000,
        //   separatePrice: false,
        //   time: "1時間30分",
        //   text: "前世療法は、年齢退行では解決しきれない問題や症状にも有効です。より深い潜在意識の中から原因を見つけ出し、癒していきます。また、前世療法は、自分の人生に前向きな変化を起こすための、気づきの療法でもあります。なぜモヤモヤしているのかわからない、本当の自分を見つけたい、どうすれば現状を良くできるかを知りたいといった方も、セラピーを通して本来の自分に気付くことで、今を輝かせて生きられるようになります。",
        // },
      ],
      addressTableList: [
        {
          id: 1,
          th: "郵便番号",
          td: "251-0042",
        },
        {
          id: 2,
          th: "住所",
          td: "藤沢市辻堂新町3-5-35",
        },
        {
          id: 3,
          th: "経路案内",
          td: "JR東海道線辻堂駅北口<br><バス><br>神奈川中央交通・ 藤21 藤沢駅北口行き(辻堂駅北口より)「新町三丁目」下車徒歩2分<br><徒歩><br>辻堂駅北口より約15分",
        },
      ],
      voiceBoxList: [
        {
          id: 1,
          imgPath: "therapy-top-ira003.webp",
          voice:
            "【前世療法】<br>結婚して子供が2人できて以降、日々の生活に追われ、思うようにいかないことに苛立ちが募り、常にモヤモヤとした気持ちがありました。相談時には、精神的に限界の状態でしたが、カウンセリングで話を聞いてもらっているだけでも、涙が出てきて気持ちが楽になりました。前世療法も思わぬ国の男の人でしたが、今の私にどう関係していて、どうすれば楽になれるのかを知ることができ、本当にお願いして良かったです。",
          man: "46才 女性 A.Y主婦",
        },
        {
          id: 2,
          imgPath: "therapy-top-ira005.webp",
          voice:
            "【インナーチャイルド】<br>幼少の頃から、母に甘えられなかったことが、私が一人でいることに関係していることを知り、ビックリしました。自分が辛かったと認めることができたのかな？終わった後に胸の部分が熱くなったことが初めての感覚で、体も温かくなった感じがしました。",
          man: "56才 女性 Yさん会社員",
        },
        {
          id: 3,
          imgPath: "therapy-top-ira008.webp",
          voice:
            "【ヨガ＆瞑想体験】<br>いつもは、足のマッサージをお願いしているので、ヨガと瞑想を一緒にと言われ驚きましたが、膝が心配だったけどきっと私に合わせてくれたんだよね？ありがとう。簡単な動作だったけ私にはどちょうどよい感じでした。瞑想初めてしたけど気持ち良かった。香も好きな香りでgood!",
          man: "62才 女性 パート＆自営手伝い",
        },
        {
          id: 4,
          imgPath: "therapy-top-ira014.webp",
          voice:
            "【退行・インナーチャイルド】<br>ヒプノセラピーに興味あって体験したくてきました。感情止められず、あんな大きな声で泣きごめんなさい。終わったあとはとても癒され、スッキリしました。",
          man: "38才 男性 スピリチュアルカウンセラー",
        },
        {
          id: 5,
          imgPath: "therapy-top-ira001.webp",
          voice:
            "【グリーフセラピー】<br>今日は、ありがとうございました。前の(亡くなった)彼に会わせてくれてありがとうございました。彼に悪いことしたって思っていたから彼の言葉が聞けて良かった。本当に横にいる感じがしました。なんだか心が軽くなりました。",
          man: "48才 女性 パート（オンラインにて）",
        },
      ],
      qaBoxList: [
        {
          id: 1,
          qestion: "オンラインでのセラピーとサロンでのセラピーは、どちらがおススメですか？",
          answer:
            "セラピーの内容はどちらも同じです。最近は、社会全体として外出を控える傾向にあることから、在宅でのセラピーを好まれる方も多くいらっしゃいます。また、オンラインセラピーは、お住まいの場所に関係なく受けていただけるのがメリットです。サロンでは、アロマの香りと心地良い音楽の中、セラピーを行います。アロママッサージと組み合わせ、心身共に、よりリラックスしたいという方は、サロンでのセラピーもおすすめです。",
        },
        {
          id: 2,
          qestion: "セラピーは継続的に受ける必要がありますか？",
          answer:
            "改善したいお悩みの大きさ・内容・求めるレベルなどによって、必要なセラピーの回数は異なります。一度のセラピーで悩みを解消できる場合もあれば、複数回のセラピーが必要になることもあります。<br>どなたも悩みはひとつではなく、多くの悩みが複雑に絡み合っていることがほとんどですので、状況を見ながら、適切なセラピーをご提案させていただきます。",
        },
        {
          id: 3,
          qestion: "セラピーを受ける際の注意点や用意するものありますか？",
          answer:
            "メンタル面で病院へ通われている方、癲癇などの発作系の持病をお持ちの方は、ご予約時に必ずご相談ください。セラピー中、過去の辛い体験を思い出すことにより、お体に反応が出る場合がございます。涙を流される方もいらっしゃいますので、ハンカチ等をご用意ください。オンラインセラピーを受けられる際は、ご自身が落ち着ける場所にてお願いします。最善の結果を得るためにも、セッション中はセラピストの指示に従ってください。",
        },
        {
          id: 4,
          qestion: "体が硬く、ポーズなどが上手くとれませんが、ヨーガの効果は得られますか？",
          answer:
            "yuntakuのヨーガは、ポーズをとるために行うものではありません。自立神経(交感神経⇄副交感神経)のはたらきを、自身の感覚でつかめるように促し、心と体を一体化させやすくするために行います。これは、インドの行者が自分の体力維持のために洞穴で行っているものを真似たもので、お客様に合わせて行う、自重を使った簡単な運動です。 ヨーガをした後は、瞑想に集中しやすくなります。",
        },
        {
          id: 5,
          qestion: "ヒプノセラピーは、実際に効果が実感できますか？",
          answer:
            "ヒプノセラピーで効果を得るには、お客様自身のセラピーに対する向き合い方、セラピストとの信頼関係が重要になります。そのため、まずは、お客様自身がセラピーに対して否定的な感情を持つことなく、前向きな姿勢で臨むことが必要になります。また、「今の自分を変えたい」「悩みを解消したい」と、お客様自身が真にそう願っている状態であることも大切です。このような状態を作ることで、効果を得やすくなります。",
        },
        {
          id: 6,
          qestion: "相談内容を秘密にしてもらえますか？",
          answer:
            "守秘義務がございますので、お客様の許可なく、お客様の個人情報やご相談内容を他者に漏らすことは一切ございません。どうぞ安心してご依頼ください。",
        },
        {
          id: 7,
          qestion:
            "表示してある料金以外にお金はかかりませんか？",
          answer:
            "セラピーに関して、メニューに表示された料金以外は一切いただきません。当サロンではリラックス効果を高めるアロマオイルの販売もしておりますが、こちらはご希望をいただいた方にのみ販売しております。 安心してご利用ください。",
        },
        {
          id: 8,
          qestion:
            "相談内容はどんなことでも大丈夫ですか？",
          answer:
            "どんな内容でも対応させていただきます。お悩みや、体の不調に対しても、今まで心身相関で学んできた知識を活かし、可能な限り対応させていただきます。どうぞお気兼ねなく、何でもご相談ください。",
        },
      ],
      flowBoxList: [
        {
          id: 1,
          no: "01",
          title: "ご希望のお問い合わせ方法にて、ご予約のお問い合わせ",
          text: "お問い合わせフォームの場合は、「ご予約について」にチェックを入れ、ご希望のメニューとご希望のお日にちを第1～3希望までお知らせください。InstagramのDM、LINEの場合は、直接のメッセージにて、上記内容をお知らせください。予約システムをご利用の場合は、予約システム上の説明を参照してください。",
          yazirushi: true,
        },
        {
          id: 2,
          no: "02",
          title: "セラピーの日にちを決定",
          text: "こちらから折り返しご連絡をし、セラピーの日程を調整します。日程が決まり次第、セラピーの当日までにご準備をお願いしたい内容などについてお伝えします。その際、ご不明な点などがございましたら、お気兼ねなく、お申し付けください。なお、予約システムでのご予約の場合は、その場で予約が確定いたします。",
          yazirushi: true,
        },
        {
          id: 3,
          no: "03",
          title: "セラピーの3日前にリマインドメッセージを確認",
          text: "セラピー予約日の3日前になりましたら、こちらからリマインドメッセージをお送りします。これ以降のキャンセルは、キャンセル料50%が発生いたしますのでご注意くださいませ。",
          yazirushi: true,
        },
        {
          id: 4,
          no: "04",
          title: "ご予約の日時にセラピーを受ける",
          text: "対面の場合は、自宅サロンにて、オンラインの場合はZoomにてセラピーを行います。オンラインの場合は、事前にZoomの招待メールをお送りさせていただきます。セラピーの終わりには、日々の生活で行ってほしいことについて処方箋を出し、アドバイスをさせていただきます。",
          yazirushi: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  //   created() {
  // var VueScrollTo = require('vue-scrollto');

  // var options = {
  //     container: '#container',
  //     easing: 'ease-in',
  //     lazy: false,
  //     offset: -60,
  //     force: true,
  //     cancelable: true,
  //     onStart: function(element) {
  //       console.log(element);
  //       // scrolling started
  //     },
  //     onDone: function(element) {
  //       console.log(element);
  //       // scrolling is done
  //     },
  //     onCancel: function() {
  //       // scrolling has been interrupted
  //     },
  //     x: false,
  //     y: true
  // }

  // var cancelScroll = VueScrollTo.scrollTo("about", 500, options)
  // console.log(cancelScroll);
  //   },
  // mounted() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash !== "") {
  //       setTimeout(function(){
  //         const hash = this.$route.hash;
  //         alert('移動するよ！');
  //         this.$scrollTo(hash, 0, {});
  //       }.bind(this), 1000,);
  //     }
  //   });
  // },
  //   mounted() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash !== "") {
  //       // alert('hashある！');
  //       setTimeout(function(){
  //         const hash = this.$route.hash;
  //         console.log("koko");
  //         this.$scrollTo(hash, 0, {});
  //       }.bind(this), 1500,);
  //     }else {
  //       // alert('hashない！');
  //     }
  //   });
  // },
  created() {
    this.$nextTick(() => {
      this.OriginalVueScrollTo(this.$route.query.position);
    });
  },
  methods: {
    OriginalVueScrollTo(id) {
      //
      let element = "#" + id;
      let VueScrollTo = require("vue-scrollto");

      //
      let duration = 0; // 実行までの時間（ms）

      //
      let option = {
        offset: -90, // ゴール地点からどれだけズレた位置に飛ぶか
      };

      // scroll実行
      VueScrollTo.scrollTo(element, duration, option);
    },
  },
};
</script>
<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Noto+Serif+JP:wght@400;700&display=swap");
.sample {
  font-family: var(--fontfamilyMain);
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
}
.top {
  img {
    vertical-align: top;
    width: 100vw;
    -webkit-backface-visibility: hidden;
  }
}
.back01 {
  background-image: url(~@/assets/therapy-top-hito-back_01.webp);
  
}
.back02 {
  background-image: url(~@/assets/therapy-top-hito-back_02.webp);
}
.back03 {
  background-image: url(~@/assets/therapy-top-hito-back_03.webp);
}
.back04 {
  background-image: url(~@/assets/therapy-top-hito-back_04.webp);
}
.back05 {
  background-image: url(~@/assets/therapy-top-hito-back_05.webp);
}
.back06 {
  background-image: url(~@/assets/therapy-top-hito-back_06.webp);
}
.back07 {
  background-image: url(~@/assets/therapy-top-hito-back_07.webp);
}
.back08 {
  background-image: url(~@/assets/therapy-top-hito-back_08.webp);
}
.back09 {
  background-image: url(~@/assets/therapy-top-hito-back_09.webp);
}
.back10 {
  background-image: url(~@/assets/therapy-top-hito-back_10.webp);
}
.back11 {
  background-image: url(~@/assets/therapy-top-hito-back_11.webp);
}
.back12 {
  background-image: url(~@/assets/therapy-top-hito-back_12.webp);
}
.back13 {
  background-image: url(~@/assets/therapy-top-hito-back_27.webp);
}
.introduction-back {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
    background-position: 0% 50%;
  @media screen and (max-width: 767px) {
    background-position: 60% 50%;
  }
}
.space-top-50 {
  margin-top: 50px;
}
</style>