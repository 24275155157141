<template>
  <div class="sample-menu-menu">
    <div class="menu-box-cover">
<div class="menu-box">
        <img
        :src="require(`@/assets/${imgPath}`)"
        :alt="{ man }"
        class="photo"
      />
<p v-html="voice" class="voice"></p>
    </div>
    <p v-html="man" class="man"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "Samplemenu",
  components: {
    // UiHeading,
  },
  props: {
    imgPath: String,
    voice: String,
    man:String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.menu-box-cover{
  background-color: white;
  margin-bottom: 30px;
  border-radius: 35px;
  padding: 40px 50px 30px 50px;
  width: 70%;
  @media screen and (min-width:768px) and ( max-width:1024px){
    width: 100%;
  }
  @media screen and (min-width:768px) and ( max-width:900px){
    padding: 20px 25px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }
  .menu-box{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px){
      display: block;
    }
    .photo{
      width: 130px;
      height: 100%;
      margin-right: 10px;
      @media screen and (max-width: 767px) {
        margin-right: 0px;
        margin-bottom: 10px;
        width: 75px;
      }
    }
    .voice{
      width: 75%;
      text-align: left;
      @media screen and (min-width:768px) and ( max-width:1024px){
        width: 80%;
      }
      @media screen and (max-width: 767px) {
     width: 100%;
      }
    }
  }
    .man{
      text-align: right;
    }
}
</style>