<template>
  <div class="sample-message space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <img alt="セラピストイメージ" src="@/assets/therapy-top-message.webp" />
      <!-- 本番はyoutubeのパスに変更する -->

      <div class="coment">
        <p>
          「軽やかな笑い」や「幸せ」「癒しのエネルギー」が、自然と中から溢れ出てくること、これが私の強みだと感じています。<br />
          笑顔や幸福感の良いところは、周りにも伝染するところ、そして、周りに安心感を与えられるところです。<br />
          私から溢れ出る「軽やかな笑い」や「幸せ」が、多くの人の笑顔や心地良さ、安心感につながり、そこから幸せの輪がどんどんと広がっていくとしたら、これほど嬉しいことはありません。<br />
          そんな想いを胸に、サロンyuntakuでは、多くの人が自然な形で幸せへの道を歩んでいけるよう、温かく寄り添ったサポートをおこなっております。
        </p>
        <p>
          私のセラピーを受けていただくことで、たとえば、こんな効果をご実感いただけます。
        </p>
        <p>
          ①自然体の自分を知ることができる<br />
          ②後悔してきた出来事で自分を責めなくなる（自分にはなまるをつける）<br />
          ③"こころ"も"からだ"も楽にまぁ～るくなる <br />
          ④睡眠の質がよくなる<br />
          ⑤浮腫みがとれる<br />
          ⑥よくわからなかった不調が楽になる<br />
          ⑦親子や、夫婦、仕事などの人間関係がよくなる<br />
          <!-- ⑧精神的にも体も軽く感じられる<br />
          ⑨よくわからなかった不調が楽になる<br />
          ⑩親子や、夫婦、仕事などの人間関係がよくなる<br /> -->
        </p>
        <p class="last-text">
          そして、セラピー後のあなたはサイコーの笑顔になっています。<br />
          あなたとの関わりによって、私の中に幸せが生まれ、その幸せがあなたの中にも広がっていきます。<br />
          自然でありながらも、心地良いその感覚を、どうぞサロンyuntakuでご体験ください。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "SampleMessage",
  components: {
    UiHeading,
  },
  props: {
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  p {
    margin-bottom: 40px;
  }
  .coment {
    margin-top: 100px;
    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
    h4 {
      margin-bottom: 20px;
      text-align: center;
    }
    p {
      text-align: left;
    }
    .last-text{
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;

      }
    }
  }
}
</style>