<template>
  <div class="sample-qa space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <SampleQaQaBox
        v-for="qaBoxListData in qaBoxList"
        :key="qaBoxListData.id"
        :qestion="qaBoxListData.qestion"
        :answer="qaBoxListData.answer"
        class="item"
      />

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleQaQaBox from "@/components/Sample/Qa/QaBox.vue";

export default {
  name: "SampleQa",
  components: {
    UiHeading,
    SampleQaQaBox,
  },
  props: {
    qaBoxList: Array,
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover{
  .item{
    margin-bottom: 30px;
      border: 1px solid #a7a5a4;
      @media screen and (max-width: 767px){
        margin-bottom: 30px;
      }
  }
}


</style>