<template>
  <div class="ui-bar2">
    <div class="outer">
      <div class="bar-box">
        <div class="bar-no">
          <p>{{ no }}</p>
        </div>
        <div class="bar-text">
          <p v-html="title" class="title"></p>
          <p v-html="text" class="text"></p>
        </div>
      </div>
    </div>

    <svg
      data-name="矢印"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 39"
      width="50px"
      height="50px"
      v-show="yazirushi === true"
    >
      <defs></defs>
      <polygon class="cls-1" points="0 0 32 39 64 0 0 0" />
    </svg>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiBar2",
  components: {
  },
  props: {
    no: String,
    title: String,
    text: String,
    yazirushi: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  background-color: white;
  margin: 20px 0;
  padding: 40px 0;
  @media screen and (max-width: 767px) {
    margin-top:10px;
  }
  .bar-box {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .bar-no {
      min-width: 160px;
      border-right: 1px solid #a7a5a4;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: var(--fontfamilySub);
      @media screen and (max-width: 767px){
        border-right: none;
      }
      p {
        font-size: 32px;
        color: var(--base);
      }
    }
    .bar-text {
      width: calc(100% -160px);
      padding-left: 30px;
      padding-right: 50px;
      min-height: 137px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
        @media screen and (max-width: 767px) {
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        }

      .title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 5px;
        font-family: var(--fontfamilySub);
        @media screen and (max-width: 767px){
          padding-top: 10px;
          text-align: left;
          font-size: 18px;
        }
      }
      .text{
        text-align: left;
        @media screen and (max-width: 767px){
          padding-top: 20px;
        }
      }
    }
  }

}
.cls-1 {
  fill: var(--base);
}

</style>