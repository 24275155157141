<template>
  <div class="top-button" ontouchstart="">
    <div
      class="to-top-button"
      :class="{ 'over-baseline': scrollY >= 500 }"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: -70,
      }"
    >
      <div class="top">
        <svg
          id="レイヤー_1"
          data-name="レイヤー 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 205 205"
                    width="70px"
          height="70px"
          class="bt"
        >
          <defs></defs>
          <rect class="cls-1" width="205" height="205" />
          <circle class="cls-2" cx="102.5" cy="102.5" r="100" />
          <polyline
            class="cls-3"
            points="67.24 125.02 102.5 81.74 137.76 125.02"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiToTopButton",
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //スクロールすると実行される
      if (this.scrollTimer === null) {
        //初期値はnull
        this.scrollTimer = setTimeout(
          //ここでnullじゃなくてsetTimeoutになる
          function () {
            //実行するセット
            this.scrollY = window.scrollY; //ウィンドウトップからの画面距離
            clearTimeout(this.scrollTimer); //scrollTimerをリセット
            this.scrollTimer = null; //初期値に戻す
          }.bind(this), //第一引数
          200 //第二引数(ミリ秒後)←0.2秒後にはじまるっていう意味
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-1,
.cls-3 {
  fill: none;
}
.cls-2 {
  fill: var(--base);
}
.cls-3 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}
.to-top-button {
  display: none;
  position: fixed;
  width: 60px;
  height: auto;
  z-index: 999;
  right: 2%;
  bottom: 6%;
  cursor: pointer;
  // opacity: 0.7;
  // border: 2px white solid;

  .bt {
    @media screen and (max-width: 767px) {
      width: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 45px;
    right: 4%;
    bottom: 9%;
  }

  &:hover {
    opacity: 0.8;
  }
}

.over-baseline {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>