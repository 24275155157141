import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'


import Sample from '../views/Sample.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Sample,
    // redirect: '/sample01',
    meta: {
      title: 'ヨーガ・瞑想・ヒプノセラピーなら「サロンyuntaku」',
      description: 'サロンyuntaku(ユンタク)は、「生きづらい人生から抜け出して変わりたい！」そんなあなたを、望み通りの人生にナビゲートするサロンです。ヨーガや瞑想、ヒプノセラピーによって、本当の自分を知り、心身のバランスを整え、自分らしい人生を歩めるようにサポートします。ぜひ、ご体験ください。サロンは藤沢市辻堂にございます。セラピーはオンラインでも可能です。'
    }
  },
  //ワイルドカードのルートをリダイレクトする
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

next();

  // if (to.path.slice(-1) === "/") {
  //   next(to.path.slice(0, -1));
  // } else {
  //   next();
  // }
});

export default router
